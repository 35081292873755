import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navPop:false,
    qrCode:false,
    type:"",
  },
  mutations: {
    setNavPop(state,navPop){
      state.navPop = navPop;
    },
    setQrCode(state,qrCode){
      state.qrCode = qrCode;
    },
    setType(state,type){
      state.type = type;
    }
  },
  actions: {
  },
  getters:{
    getNavPop:state=>{
      return state.navPop
    },
    getQrCode:state=>{
      return state.qrCode
    },
    getType:state=>{
      return state.type
    },
  },
  modules: {
  }
})
