<!--
 * @Author: YourName
 * @Date: 2022-09-28 11:21:53
 * @LastEditTime: 2023-09-14 11:16:44
 * @LastEditors: YourName
 * @Description: 
 * @FilePath: \website_taotrade_mobile\src\App.vue
 * 版权声明
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  mounted(){
      //是否移动设备（手机/PAD）
      // let sUserAgent = navigator.userAgent.toLowerCase();
      //var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      // let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      // let bIsMidp = sUserAgent.match(/midp/i) == "midp";
      // let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      // let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      // let bIsAndroid = sUserAgent.match(/android/i) == "android";
      // let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      // let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      // if(!(bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)){
      //   window.location.href = "https://www.taotrade.cn"
      // } 
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
